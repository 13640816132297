(function ($) {
  "use strict";
  $(document).ready(function () {
    // Get started!
    //Menu Mobile
    $(".burger").on("click", function (e) {
      e.preventDefault();
      if (!$(this).hasClass("open")) {
        openMenu();
      } else {
        closeMenu();
      }
    });

    function openMenu() {
      $(".menu-bg").addClass("animate");
      $(".burger").addClass("open");
      $(".x, .z").addClass("collapse-icon");
      $(".menu-navigation").addClass("animate");
      $("body").css({
        overflow: "hidden",
        height: "100vh",
      });
      setTimeout(function () {
        $(".y").hide();
        $(".x").addClass("rotate30");
        $(".z").addClass("rotate150");
      }, 70);
      setTimeout(function () {
        $(".x").addClass("rotate45");
        $(".z").addClass("rotate135");
      }, 120);
    }

    function closeMenu() {
      $(".menu-navigation").removeClass("animate");
      $("body").css({
        overflow: "",
        height: "",
      });

      setTimeout(function () {
        $(".burger").removeClass("open");
        $(".x").removeClass("rotate45").addClass("rotate30");
        $(".z").removeClass("rotate135").addClass("rotate150");
        $(".menu-bg").removeClass("animate");

        setTimeout(function () {
          $(".x").removeClass("rotate30");
          $(".z").removeClass("rotate150");
        }, 50);
        setTimeout(function () {
          $(".y").show();
          $(".x, .z").removeClass("collapse-icon");
        }, 70);
      }, 100);
    }

    $("img.svg").each(function () {
      var $img = $(this);
      var imgID = $img.attr("id");
      var imgClass = $img.attr("class");
      var imgURL = $img.attr("src");

      $.get(
        imgURL,
        function (data) {
          // Get the SVG tag, ignore the rest
          var $svg = jQuery(data).find("svg");

          // Add replaced image's ID to the new SVG
          if (typeof imgID !== "undefined") {
            $svg = $svg.attr("id", imgID);
          }
          // Add replaced image's classes to the new SVG
          if (typeof imgClass !== "undefined") {
            $svg = $svg.attr("class", imgClass + " replaced-svg");
          }

          // Remove any invalid XML tags as per http://validator.w3.org
          $svg = $svg.removeAttr("xmlns:a");

          // Check if the viewport is set, else we gonna set it if we can.
          if (
            !$svg.attr("viewBox") &&
            $svg.attr("height") &&
            $svg.attr("width")
          ) {
            $svg.attr(
              "viewBox",
              "0 0 " + $svg.attr("height") + " " + $svg.attr("width")
            );
          }

          // Replace image with new SVG
          $img.replaceWith($svg);
        },
        "xml"
      );
    });

    if ($(".padding-section").length > 0) {
      var fixedBgHeight = $(".fixed-content").innerHeight();
      $(".padding-section").css("padding-top", fixedBgHeight);
      $(window).on("resize", function () {
        fixedBgHeight = $(".fixed-content").innerHeight();
        $(".padding-section").css("padding-top", fixedBgHeight);
      });
    }

    if ($(".detail-works-page").length > 0) {
      
      $(".slide-scene").fullpage({
        scrollOverflow: true,
        responsive: 767,
        onLeave: function (anchorLink,index) {
          if ($(window).width() > 767) {
            if(index === 5 || index == 6){
              if($(".thankyou-image-animate").hasClass('aos-animate')){
                $(".thankyou-image-animate").addClass("aos-animate");
              } 
              else {
                $(".thankyou-image .aos-init").removeClass("aos-animate");
              }
            }else {
              setTimeout(function () {
                $(".slide-scene .section [data-aos]").each(function () {
                  $(this).removeClass("aos-animate");
                });
              }, 600);
            }
          } else {
            setTimeout(function () {
              $(".slide-scene .section [data-aos]").each(function () {
                $(this).removeClass("aos-animate");
              });
            }, 600);
          }
        },
        afterLoad: function (anchorLink,index) {
          setTimeout(function () {
            if ($(window).width() > 767) {
              $(".slide-scene .section.active [data-aos]").each(function () {
                $(this).addClass("aos-animate");
              });
            }
           
          }, 1);
        },
      });


      if ($(window).width() < 767) {
        $("[data-aos]").each(function () {
          $(this).removeClass("aos-init");
        });
        AOS.init({
          mirror: true,
          offset: 80,
          duration: 1000,
        });
      } else {
        $("[data-aos]").each(function () {
          $(this).addClass("aos-init");
        });
      }

      $(window).on("resize", function () {
        if ($(window).width() < 767) {
          $("[data-aos]").each(function () {
            $(this).removeClass("aos-init");
          });
          AOS.init({
            mirror: true,
            offset: 80,
            duration: 1000,
          });
        } else {
          $("[data-aos]").each(function () {
            $(this).addClass("aos-init");
          });
        }
      });


      $(".trigger-play").on("click", function () {
        $("#modalVideo").modal("show");
      });

      var videoSrc = $("#pcjVideo").attr("video-src");
      $("#modalVideo").on("hide.bs.modal", function () {
        $("#pcjVideo").html("");
      });
      $("#modalVideo").on("show.bs.modal", function () {
        $("#pcjVideo").html(
          '<iframe width="100%" height="315" src="' +
            videoSrc +
            '?autoplay=1&modestbranding=1&rel=0&fs=1&enablejsapi=1" frameborder="0" allowfullscreen allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>'
        );
      });

      $(".smm-slider .years-slider").slick({
        arrows: true,
        centerMode: true,
        infinite: false,
        slidesToShow: 3,
        prevArrow: '<button class="slick-prev" type="button"><i></i></button>',
        nextArrow: '<button class="slick-next" type="button"><i></i></button>',
        initialSlide: 1,
        swipe: false,
        swipeToSlide: false,
        touchMove: false,
        draggable: false,
        asNavFor: ".smm-slider .years-content-slider",
      });

      $(".smm-slider .years-content-slider").slick({
        arrows: false,
        fade: true,
        infinite: false,
        slidesToShow: 1,
        initialSlide: 1,
        swipe: false,
        swipeToSlide: false,
        touchMove: false,
        draggable: false,
        asNavFor: ".smm-slider .years-slider",
      });

      var currentSlide = $(".smm-slider .years-slider").slick(
        "slickCurrentSlide"
      );
      var slickInstance1 = $(".smm-slider .years-slider")[0];
      if (currentSlide === 1) {
        $(".slick-prev").addClass("slick-disabled");
        slickInstance1.slick.$prevArrow.off("click.slick");
      }
      $(".smm-slider .years-slider").on(
        "afterChange",
        function (event, slick, currentSlide, nextSlide) {
          if (currentSlide === 3) {
            slickInstance1.slick.$nextArrow.off("click.slick");
            $(".slick-next").addClass("slick-disabled");
          } else if (currentSlide == 1) {
            slickInstance1.slick.$prevArrow.off("click.slick");
            $(".slick-prev").addClass("slick-disabled");
          } else {
            $(".slick-next").removeClass("slick-disabled");
            $(".slick-prev").removeClass("slick-disabled");
            slickInstance1.slick.initArrowEvents();
          }
        }
      );
      $(".smm-slider .years-slider").on(
        "beforeChange",
        function (event, slick, currentSlide, nextSlide) {
          switch (nextSlide) {
            case 1:
              $(".deco-wrapper").removeClass("move");
              $(".deco-wrapper").removeClass("move2");
              break;
            case 2:
              $(".deco-wrapper").addClass("move");
              $(".deco-wrapper").removeClass("move2");
              break;
            case 3:
              $(".deco-wrapper").removeClass("move");
              $(".deco-wrapper").addClass("move2");
              break;
            // case 3:
            //   $('.deco-wrapper').removeClass('move')
            //   $('.deco-wrapper').addClass('move2')
            //   break;
            default:
              return false;
          }
        }
      );

      $(".pcj-slider .years-slider").slick({
        arrows: true,
        centerMode: true,
        infinite: false,
        slidesToShow: 3,
        prevArrow: '<button class="slick-prev" type="button"><i></i></button>',
        nextArrow: '<button class="slick-next" type="button"><i></i></button>',
        initialSlide: 1,
        swipe: false,
        swipeToSlide: false,
        touchMove: false,
        draggable: false,
        asNavFor: ".pcj-slider .years-content-slider",
      });
      $(".pcj-slider .years-content-slider").slick({
        arrows: false,
        fade: true,
        infinite: false,
        slidesToShow: 1,
        initialSlide: 1,
        swipe: false,
        swipeToSlide: false,
        touchMove: false,
        draggable: false,
        asNavFor: ".pcj-slider .years-slider",
      });
      var currentSlide = $(".pcj-slider .years-slider").slick(
        "slickCurrentSlide"
      );
      var slickInstance2 = $(".pcj-slider .years-slider")[0];
      if (currentSlide === 1) {
        $(".slick-prev").addClass("slick-disabled");
        slickInstance2.slick.$prevArrow.off("click.slick");
      }
      $(".pcj-slider .years-slider").on(
        "afterChange",
        function (event, slick, currentSlide, nextSlide) {
          if (currentSlide === 3) {
            slickInstance2.slick.$nextArrow.off("click.slick");
            $(".slick-next").addClass("slick-disabled");
          } else if (currentSlide == 1) {
            slickInstance2.slick.$prevArrow.off("click.slick");
            $(".slick-prev").addClass("slick-disabled");
          } else {
            $(".slick-next").removeClass("slick-disabled");
            $(".slick-prev").removeClass("slick-disabled");
            slickInstance2.slick.initArrowEvents();
          }
        }
      );
    }
    $("audio").audioPlayer();

    if($(".la-works-page").length > 0) {
      AOS.init({
        // mirror: true,
        offset: 80,
        duration: 1000,
      });
    }
  });
})(jQuery);
